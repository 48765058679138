<template>
  <div v-if="content" class="info-icon-n-gray" :class="isError?'error':''">
    <div class="help">
      <img v-if="!isError" src="@imgPath/img/svg/icons/question_mark_circle.svg" alt width="20px" />
       <img v-if="isError" src="@imgPath/img/svg/icons/exclamation-mark.svg" alt width="20px" />
    </div>
    <div class="help-pass-content">
      <div class="wrapper">
        <p v-html="content"></p>
      </div>
    </div>
    <!-- </CRow>
       </CCardBody>
  </div>
    </CCard>-->
  </div>
</template>

<script>
import { COMPONENT_NAME} from "../util/constants";

export default {
  name: COMPONENT_NAME.C_HELP_TIP,

  props: {
    content: {
      type: String,
      default: null
    },
    isError: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
.error{
    background-color: #bbbbbb;
    border: 1px #bbbbbb solid;
    border-radius: 100px;    
    max-width: 32px;
    padding: 5px;
}
</style>